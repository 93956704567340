import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core';
import {
  ApiMetadataApiService,
  ExadataUser,
  ListExadataUserByEmailAddressPath,
  ListExadataUserByEmailAddressResp,
  UpdateExadataUserPath,
  UpdateExadataUserResp,
  UpdateExadataUserRqst,
} from '@xpo-ltl/sdk-apimetadata';
import { User } from '@xpo-ltl/sdk-common';

@Component({
  selector: 'app-pwd-reset',
  templateUrl: './pwd-reset.component.html',
  styleUrls: ['./pwd-reset.component.scss'],
})
export class PwdResetComponent implements OnInit {
  passwordForm: FormGroup;
  databaseList: string[] = [];
  user: User;
  showingPassword: boolean = false;
  iconImage: string = 'visibility_off';
  passwordInputType: string = 'password';

  constructor(
    private fb: FormBuilder,
    private apiMetadataService: ApiMetadataApiService,
    private xpoSnackBar: XpoSnackBar,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.user = this.route.snapshot.data.loginUser as User;
    const pathParams = {
      emailAddress: encodeURIComponent(this.user.emailAddress),
    } as ListExadataUserByEmailAddressPath;
    this.apiMetadataService
      .listExadataUserByEmailAddress(pathParams)
      .subscribe((response: ListExadataUserByEmailAddressResp) => {
        if (response.exadataUser) {
          response.exadataUser.forEach((exadata) => {
            this.databaseList.push(exadata.databaseCd);
          });
        }
      });
  }

  showHidePassword() {
    this.showingPassword = !this.showingPassword;
    this.iconImage = this.showingPassword ? 'visibility' : 'visibility_off';
    this.passwordInputType = this.showingPassword ? 'text' : 'password';
  }

  private initForm(): void {
    const upperCaseValidator: ValidatorFn = (control: AbstractControl) => {
      return /.*[A-Z].*/.test(control.value) ? null : { upperCase: true };
    };
    const lowerCaseValidator: ValidatorFn = (control: AbstractControl) => {
      return control.value && /.*[a-z].*/.test(control.value) ? null : { lowerCase: true };
    };
    const numberValidator: ValidatorFn = (control: AbstractControl) => {
      return /.*[0-9].*/.test(control.value) ? null : { number: true };
    };
    const symbolValidator: ValidatorFn = (control: AbstractControl) => {
      return /[#$_]+/.test(control.value) ? null : { symbol: true };
    };
    const lengthValidator: ValidatorFn = (control: AbstractControl) => {
      return control.value && control.value.length >= 12 ? null : { length: true };
    };
    const alphaCharStart: ValidatorFn = (control: AbstractControl) => {
      return control.value && /^[A-Za-z]/.test(control.value) ? null : { alphaCharStart: true };
    };
    const notContain: ValidatorFn = (control: AbstractControl) => {
      return control.value && /^[^ ]*$/.test(control.value) && /^[A-Za-z0-9#$_]+$/.test(control.value)
        ? null
        : { notContain: true };
    };
    const serverName: ValidatorFn = (control: AbstractControl) => {
      const includesServer: boolean =
        this.databaseList.findIndex((db) => control.value.toLowerCase().includes(db.toLowerCase())) > -1;

      return control.value && !includesServer ? null : { serverName: true };
    };
    const userName: ValidatorFn = (control: AbstractControl) => {
      if (!control.value) {
        return { userName: true };
      }
      const input = control.value.toLowerCase();
      const includesName =
        input.includes(this.user.userId.toLowerCase()) || input.includes(this.user.givenName.toLowerCase());
      return !includesName ? null : { userName: true };
    };

    const no3identical: ValidatorFn = (control: AbstractControl) => {
      if (!control.value) {
        return { no3identical: true };
      }
      const regex = new RegExp('([A-Za-z\\d])\\1\\1');
      return !regex.test(control.value) ? null : { no3identical: true };
    };

    this.passwordForm = this.fb.group({
      database: ['All'],
      password: [
        null,
        [
          Validators.required,
          lengthValidator,
          upperCaseValidator,
          lowerCaseValidator,
          numberValidator,
          symbolValidator,
          alphaCharStart,
          notContain,
          serverName,
          userName,
          no3identical,
        ],
      ],
    });
  }

  private getRequest(): ExadataUser[] {
    if (this.passwordForm.value.database === 'All') {
      return this.databaseList.map((db: string) => {
        return {
          databaseCd: db,
          password: this.passwordForm.value.password,
        } as ExadataUser;
      });
    } else {
      return [
        {
          databaseCd: this.passwordForm.value.database,
          password: this.passwordForm.value.password,
        },
      ] as ExadataUser[];
    }
  }

  validate(error: string): boolean {
    return !this.passwordForm.get('password').hasError(error);
  }

  dbChanged(): void {
    this.passwordForm.get('password').updateValueAndValidity();
  }

  resetPassword(): void {
    const pathParams = {
      databaseName: this.passwordForm.value.database,
    } as UpdateExadataUserPath;

    const request = {
      exadataUser: this.getRequest(),
    } as UpdateExadataUserRqst;

    this.apiMetadataService.updateExadataUser(request, pathParams).subscribe((response: UpdateExadataUserResp) => {
      this.xpoSnackBar.success('User password successfully updated. Please close your browser for security purposes.');
      this.passwordForm.reset({ password: null, database: 'All' });
    });
  }
}
