import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ApiMetadataApiService } from '@xpo-ltl/sdk-apimetadata';
import { User } from '@xpo-ltl/sdk-common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoginUserResolver implements Resolve<User> {
  constructor(private apiMetadataService: ApiMetadataApiService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User> {
    return this.apiMetadataService.loggedInUser();
  }
}
