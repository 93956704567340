import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { XpoAuthenticationGuard } from '@xpo/ngx-auth';
import { AccountsComponent } from './accounts/accounts.component';
import { PwdResetComponent } from './pwd-reset/pwd-reset.component';
import { AppRoutes } from './shared/enums/app-routes.enum';
import { LoginUserResolver } from './shared/services/login-user.resolver';

const routerDefinitions: Routes = [
  {
    path: AppRoutes.ACCOUNTS,
    component: AccountsComponent,
    canActivate: [XpoAuthenticationGuard],
  },
  {
    path: AppRoutes.PWD_RESET,
    component: PwdResetComponent,
    canActivate: [XpoAuthenticationGuard],
    resolve: { loginUser: LoginUserResolver },
  },
  {
    path: '',
    redirectTo: AppRoutes.ACCOUNTS,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routerDefinitions, { enableTracing: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
